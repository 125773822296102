import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I recently polled on Twitter regarding Higher Order Components and Function as
Child, the `}<a parentName="p" {...{
        "href": "https://twitter.com/iammerrick/status/735254529474629633"
      }}>{`results`}</a>{`
were surprising to me.`}</p>
    <p>{`If you don’t know what the “Function as Child” pattern is, this article is my
attempt to:`}</p>
    <ol>
      <li parentName="ol">{`Teach you what it is.`}</li>
      <li parentName="ol">{`Convince you of why it is useful.`}</li>
      <li parentName="ol">{`Get some fetching hearts, or retweets or likes or newsletters or something,
I don’t know. I just want to feel appreciated, you know?`}</li>
    </ol>
    <h2>{`What are Function as Child Components?`}</h2>
    <p>{`“Function as Child Component”s are components that receive a function as their
child. The pattern is simply implemented and enforced thanks to React’s property
types.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyComponent`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Scuba Steve"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

MyComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`propTypes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  children`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`That is it! By using a Function as Child Component we decouple our parent
component and our child component letting the composer decide what & how to
apply parameters to the child component. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`MyComponent`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`MyComponent`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`And somebody else, using the same component could decide to apply the name
differently, perhaps to an attribute:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`MyComponent`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`img src`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`’`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`scuba`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`steves`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`picture`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`jpg’ alt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`MyComponent`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`What is really neat here is that MyComponent, the Function as Child Component
can manager state on behalf of components it is composed of, without making
demands on how that state is leveraged by its children. Let's move on to a more
realistic example.`}</p>
    <h3>{`The Ratio Component`}</h3>
    <p>{`The Ratio Component will use the current device width, listen for resize events
and call into its children with a width, height, and some information about
whether or not it has computed the size yet.`}</p>
    <p>{`First, we start out with a Function as Child Component snippet, this is common
across all Function as Child Component’s and it just lets consumers know we are
expecting a function as our child, not React nodes.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Ratio`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Ratio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`propTypes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 children`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Next lets design our API, we want a ratio provided in terms of X and Y axis
which we will then use the current width to compute, lets set up some internal
state to manage the width and height, whether or not we have even calculated
that yet, along with some propTypes and defaultProps to be good citizens for
people using our component.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Ratio`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      hasComputed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Ratio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`propTypes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  children`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

Ratio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`defaultProps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Alright so we aren’t doing anything interesting yet, let's add some event
listeners and actually calculate the width (accommodating as well for when our
ratio changes):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Ratio`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`constructor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`handleResize `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`handleResize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      hasComputed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getComputedDimensions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` x`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` y `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` width `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`container`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getBoundingClientRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      width`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` width `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`y `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`componentWillReceiveProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`next`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getComputedDimensions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`next`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`componentDidMount`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getComputedDimensions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      hasComputed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"resize"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`handleResize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`componentWillUnmount`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`removeEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"resize"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`handleResize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`handleResize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        hasComputed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          hasComputed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getComputedDimensions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`ref`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token keyword"
              }}>{`this`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`container `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` ref`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`width`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`height`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hasComputed
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

Ratio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`propTypes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  children`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`PropTypes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isRequired`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

Ratio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`defaultProps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Alright, so I did a lot there. We added some event listeners to listen for
resize events as well as actually computing the width and height using the
provided ratio. Neat, so we’ve got a width and height in our internal state, how
can we share it with other components?`}</p>
    <p>{`This is one of those things that is hard to understand because it is so simple
that when you see it you think, “That can’t be all there is to it.” but this
`}<strong parentName="p"><em parentName="strong">{`is`}</em>{` `}</strong>{`all there is to it.`}</p>
    <h4>{`Children is literally just a JavaScript function.`}</h4>
    <p>{`That means in order to pass the calculated width and height down we just provide
them as parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`'`}</span>{`container`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`'`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
        `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`width`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`height`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hasComputed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now anyone can use the ratio component to provide a full width and properly
computed height in whatever way they would like! For example, someone could use
the Ratio component for setting the ratio on an img:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`width`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` height`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` hasComputed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
    hasComputed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`/scuba-steve-image.png`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`width`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`height`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`height`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Meanwhile, in another file, someone has decided to use it for setting CSS
properties.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`width`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` height`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` hasComputed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` width`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` height `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`Hello world!`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`And in another app, someone is using to conditionally render different children
based on computed height:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
  `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`width`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` height`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` hasComputed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
    hasComputed `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` height `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TOO_TALL`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`TallThing`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`NotSoTallThing`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Ratio`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`Strengths`}</h3>
    <ol>
      <li parentName="ol">{`The developer composing the components owns how these properties are passed
around and used.`}</li>
      <li parentName="ol">{`The author of the Function as Child Component doesn’t enforce how its values
are leveraged allowing for very flexible use.`}</li>
      <li parentName="ol">{`Consumers don’t need to create another component to decide how to apply
properties passed in from a “Higher Order Component”. Higher Order
Components typically enforce property names on the components they are
composed with. To work around this many providers of “Higher Order
Components” provide a selector function which allows consumers to choose
your property names (think redux-connects select function). This isn’t a
problem with Function as Child Components.`}</li>
      <li parentName="ol">{`Doesn’t pollute “props” namespace, this allows you to use a “Ratio”
component and a “Pinch to Zoom” component together regardless that they are
both calculating width. Higher Order Components carry an implicit contract
they impose on the components they are composed with, unfortunately this can
mean colliding prop names being unable to compose Higher Order Components
with other ones.`}</li>
      <li parentName="ol">{`Higher Order Components create a layer of indirection in your development
tools and components themselves, for example setting constants on a Higher
Order Component will be inaccessible once wrapped in a Higher Order
Component. For example:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`MyComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SomeContant `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"SCUBA"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Then wrapped by a Higher Order Component,`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` MyComponent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`RIP your constant. It is no longer accessible without the Higher Order Component
providing a function to access the underlying component class. Sad.`}</p>
    <h4>{`Summary`}</h4>
    <p>{`Most the time when you think “I need a Higher Order Component for this shared
functionality!” I hope I have convinced you that a Function as Child Component
is a better alternative for abstracting your UI concerns, in my experience, it
nearly always is, with the exception that your child component is truly coupled
to the Higher Order Component it is composed with.`}</p>
    <h4>{`An Unfortunate Truth About Higher Order Components`}</h4>
    <p>{`As an ancillary point, I believe that Higher Order Components are improperly
named though it is probably too late to try and change their name. A higher
order function is a function that does at least one of the following:`}</p>
    <ol>
      <li parentName="ol">{`Takes n functions as arguments.`}</li>
      <li parentName="ol">{`Returns a function as a result.`}</li>
    </ol>
    <p>{`Indeed Higher Order Components do something similar to this, namely, take a
Component as an argument and return a Component but I think it is easier to
think of a Higher Order Component as a factory function, it is a function that
dynamically creates a component to allow for runtime composition of your
components. However, they are `}<strong parentName="p">{`unaware`}</strong>{` of your React state and props at
composition time!`}</p>
    <p>{`Function as Child Components allows for similar composition of your components
with the benefit of having access to state, props and context when making
composition decisions. Since Function as Child Components:`}</p>
    <ol>
      <li parentName="ol">{`Take a function as an argument.`}</li>
      <li parentName="ol">{`Render the result of said function.`}</li>
    </ol>
    <p>{`I can’t help but feel they should have gotten the title “Higher Order
Components” since it is a lot like higher order functions only using the
component composition technique instead of functional composition. Oh well, for
now, we will keep calling them “Function as Child Components” which is just
wordy and gross sounding.`}</p>
    <h3>{`Examples`}</h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gist.github.com/iammerrick/c4bbac856222d65d3a11dad1c42bdcca"
        }}>{`Pinch to Zoom - Function as Child Component`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/chenglou/react-motion"
        }}>{`react-motion`}</a>{` This project
introduced me to this concept after being a long time Higher Order Component
convert.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      