import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ArticleListTitle = styled.div`
  color: var(--title-color);
  font-size: 20px;
  padding-bottom: 8px;
`;

const ArticleDescription = styled.div`
  color: var(--body-text-color);
  font-size: 16px;
`;

const ArticleContent = styled(Link)`
  line-height: 1.5;
  max-width: 320px;
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    max-width: none;
  }
`;

const ReadNow = styled.div`
  color: var(--body-text-color);
  font-size: 14px;
  padding-top: 8px;
  font-style: italic;
`;

export const ArticleList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
  align-content: space-between;
  justify-content: center;
  grid-column-gap: 16px;
  grid-row-gap: 40px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding: 16px 0;
  }
`;

export const ArticleItem = ({ title, description, slug, timeToRead }) => (
  <ArticleContent to={slug}>
    <ArticleListTitle>{title}</ArticleListTitle>
    <ArticleDescription>{description}</ArticleDescription>
    <ReadNow>{timeToRead} Minute Read &raquo;</ReadNow>
  </ArticleContent>
);
