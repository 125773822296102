import React from "react";
import Header from "./Header";
import Context from "./Context";
import Head from "./Head";
import Quote from "./Quote";
import Layout from "./Layout";
import Nest from "./Nest";
import { ArticleList, ArticleItem } from "./Articles";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import {
  MaybeStickyTitle,
  Title,
  TitleDescription,
  SectionTitle,
} from "./Titles";

import "./syntax-theme.css";
import "./article.css";

const isSameCategory = (pathA, pathB) =>
  pathA.split("/")[1] === pathB.split("/")[1];

const ArticlesQuery = graphql`
  query ArticlesQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fileAbsolutePath
          timeToRead
          frontmatter {
            title
            archived
            excerpt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const ArticleContainer = styled.div`
  display: grid;
  row-gap: 64px;
  max-width: 664px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    max-width: initial;
  }
`;

const Markdown = styled.div`
  & img {
    border: 1px solid var(--element-muted-color);
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px;
  }

  & blockquote {
    border-left: 4px solid var(--element-muted-color);
    margin-left: 0;
    padding-left: 1.5rem;
  }
`;

const Back = styled(Link)`
  font: 20px Oswald;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--title-color);

  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    display: none;
  }
`;

const Article = (props) => {
  const {
    pageContext: { frontmatter },
    children,
    uri,
  } = props;
  const isArticle = uri.split("/")[1] === "articles";
  return (
    <Context>
      <Nest>
        <Head
          title={frontmatter.title}
          excerpt={frontmatter.excerpt}
          slug={uri}
        />
        <Header />
        <Quote content={frontmatter.quote} source={frontmatter.source} />
        <Layout
          left={
            <MaybeStickyTitle>
              <Title>{frontmatter.title}</Title>
              <TitleDescription>{frontmatter.date}</TitleDescription>
              <Back to={isArticle ? "/" : "/thoughts"}>Back</Back>
            </MaybeStickyTitle>
          }
          right={
            <ArticleContainer>
              <Markdown>{children}</Markdown>
              <div>
                <SectionTitle>Recent Articles</SectionTitle>
                <ArticleList>
                  <StaticQuery
                    query={ArticlesQuery}
                    render={(data) => {
                      const recentArticles = data.allMdx.edges.filter(
                        ({ node }) =>
                          node.fields.slug !== props.path &&
                          !node.frontmatter.archived &&
                          isSameCategory(node.fields.slug, props.path)
                      );
                      const narrowed = recentArticles.slice(0, 4);

                      return narrowed.map(({ node }) => (
                        <ArticleItem
                          key={node.fileAbsolutePath}
                          timeToRead={node.timeToRead}
                          title={node.frontmatter.title}
                          description={node.frontmatter.excerpt}
                          slug={node.fields.slug}
                        />
                      ));
                    }}
                  />
                </ArticleList>
              </div>
            </ArticleContainer>
          }
        />
      </Nest>
    </Context>
  );
};

export default Article;
